<template>
  <div class="main">
    <div class="back" @click="topath('/index')">
      <div>分会管理</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
      <div>分会干事</div>
    </div>
    <div class="thetitle">IADGE分会干事</div>
    <div class="profile">
      <div class="title"><div class="infor">现任分会干事</div></div>
      <div class="hint">注：单击姓名，您可以直接向该干事发送邮件。</div>
      <div v-for="(item, index) in list" :key="index" class="list">
        <div class="left">
          <div class="">
            <div class="position">
              <span>{{ item.member_position }}</span>
              <span
                >(任职日期:{{ item.start_time?.substring(0, 4) }}年{{
                  item.start_time?.substring(5, 7)
                }}月{{ item.start_time?.substring(8, 10) }}日)
              </span>
            </div>
            <div></div>
          </div>
          <div class="tenure">
            任职期:{{ item.start_time?.substring(0, 4) }}年{{
              item.start_time?.substring(5, 7)
            }}月{{ item.start_time?.substring(8, 10) }}日 -
            {{ item.end_time?.substring(0, 4) }}年{{
              item.end_time?.substring(5, 7)
            }}月{{ item.end_time?.substring(8, 10) }}日
          </div>
        </div>
        <a class="name hoverbottom" :href="'mailto:' + item.member_email">{{
          item.member_name
        }}</a>
        <div
          class="hand hoverbottom"
          @click="topath('redactitionalofficer', item.id)"
        >
          <img
            src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/icon_modify.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
          
<script>
import { member } from "../api/twice.js";
export default {
  name: "Home",
  data() {
    return {
      list: [
        // {
        //   position: "主任",
        //   name: "某某某",
        //   Date_of_appointment: "2022年11月3日",
        //   tenure_of_office: "2022年11月3日-2024年10月31日",
        // },
        // {
        //   position: "副主任",
        //   name: "某某某",
        //   Date_of_appointment: "2022年11月3日",
        //   tenure_of_office: "2022年11月3日-2024年10月31日",
        // },
        // {
        //   position: "秘书长",
        //   name: "某某某",
        //   Date_of_appointment: "2022年11月3日",
        //   tenure_of_office: "2022年11月3日-2024年10月31日",
        // },
        // {
        //   position: "财务",
        //   name: "某某某",
        //   Date_of_appointment: "2022年11月3日",
        //   tenure_of_office: "2022年11月3日-2024年10月31日",
        // },
      ],
    };
  },
  mounted() {
    this.getmember("all");
  },
  methods: {
    topath(e, id) {
      this.$router.push({ path: e, query: { id: id } });
    },

    async getmember() {
      const data = await member({
        perPage: 4,
        page: 1,
        condition: "manager",
      });
      this.list = data.data.data;
      console.log(data, 9876543, this.list);
    },
  },
  computed: {
    submit() {
      if (this.lastname.start_time && this.lastname.end_time) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {},
};
</script>
          <style scoped lang="scss">
.main {
  padding: 100px 0px;
  max-width: 1264px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .profile {
    width: 100%;
    box-sizing: border-box;
    color: #333;
    .hint {
      margin-bottom: 12px;
    }
    .list {
      padding: 32px 0px;
      display: flex;
      justify-content: space-between;
      border-top: 2px solid #333333;
      align-items: center;
      align-content: center;
      .name {
        font-size: 20px;
        font-weight: bold;
        color: #333;
        line-height: 23px;
        cursor: pointer;

        flex: 1;
        text-align: center;
        &:hover {
          color: #c81677;
        }
      }
      .hand {
        flex: 1;
        text-align: right;
      }
      .left {
        flex: 1;
        .tenure {
          font-size: 12px;
          font-weight: 400;
          color: #777777;
          line-height: 14px;
          margin-top: 8px;
        }
        .position {
          display: flex;
          align-items: center;
          :nth-child(1) {
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            line-height: 23px;
          }
          :nth-child(2) {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            margin-left: 8px;
          }
        }
      }
    }
    .save {
      width: 364px;
      margin: 0 auto;
      text-align: center;
      height: 58px;
      background: #c81677;
      border-radius: 50px 50px 50px 50px;
      line-height: 58px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 60px;

      cursor: pointer;
    }

    .row {
      display: flex;
      margin-bottom: 20px;
      align-items: center;

      .nation {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .city {
        display: flex;
        align-items: center;
        flex: 1;
      }
      //   :nth-child(1) {
      //     font-size: 18px;
      //     font-weight: bold;
      //     color: #333333;
      //     line-height: 21px;
      //     width: 126px;
      //     text-align: right;
      //   }
      .titler {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
        width: 163px;
        text-align: right;
      }
      .left20 {
        margin-left: 20px;
      }
      //   :nth-child(2) {
      //     margin-left: 20px;
      //   }
      .input {
        margin-left: 20px;
        // margin-bottom: 40px;
        // width: 100%;
        flex: 1;
        position: relative;
        // .hint {
        //   font-size: 12px;
        //   font-weight: 400;
        //   color: #333333;
        //   line-height: 14px;
        //   margin-top: 4px;
        //   position: absolute;
        //   margin-bottom: 20px;
        //   margin-left: 20px;
        .hintsize {
          position: absolute;
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          top: -24px;
          :nth-child(2) {
            margin-top: 4px;
          }
        }
        // }
      }
    }
    .date {
      margin-top: 46px;
    }

    .margin40 {
      margin-bottom: 40px;
    }
    .theaddress {
      margin-top: 40px;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      position: relative;
      margin-bottom: 32px;

      .infor {
        display: flex;
        align-items: center;
        margin-left: 10px;
        &::before {
          content: "";
          width: 6px;
          height: 24px;
          background: #c81677;
          position: absolute;
          left: 0px;
        }
      }
    }
  }
  .back {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    top: 20px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 17px;
      transform: rotate(180deg);
      margin: 0 6px;
    }
    div:hover {
      text-decoration: underline;
      color: #c81677;
    }
  }
}
</style>
          